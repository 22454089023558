import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SelectionPage.css';
import sultanahmet from '../assets/images/selectionPage/sultanahmet.png'
import hotel1207 from '../assets/images/selectionPage/kaleiçi.png'

function SelectionPage() {
  const navigate = useNavigate();

  return (
    <div className="split-container">
      <div 
        className="split left" 
        onClick={() => navigate('/kaleicihotel/home')}
      >
        <div className="hotel-content">
          <div className="logo-container">
            <img src={hotel1207} alt="1207 Kaleiçi Hotel Logo" className="hotel-logo" />
          </div>
          <div className="hotel-details">
            <h2>1207 Kaleiçi Hotel</h2>
            <p>Antalya'nın kalbinde tarihi bir deneyim</p>
            <div className="explore-btn">KEŞFET</div>
          </div>
        </div>
      </div>

      <div 
        className="split right" 
        onClick={() => navigate('/sultanahmethotel/home')}
      >
        <div className="hotel-content">
          <div className="logo-container">
            <img src={sultanahmet} alt="Sultanahmet Hotel Logo" className="hotel-logo" />
          </div>
          <div className="hotel-details">
            <h2>Sultanahmet Hotel</h2>
            <p>İstanbul'un tarihi dokusunda lüks konaklama</p>
            <div className="explore-btn">KEŞFET</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectionPage; 