import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/Common/ScrolToTop/ScrolToTop';
import Header from './components/Common/Header/Header';
import HeaderAlzer from './componentsAlzer/Common/Header/Header.jsx';
import FooterAlzer from './componentsAlzer/Common/Footer/Footer';
import Footer from './components/Common/Footer/Footer';
import Home from './pages/Home/Home';
import RoomType from './pages/RoomType/RoomType';
import RoomTypeAlzer from './pages/AlzerHotel/RoomType/RoomType.jsx';
import RoomTypeDetailAlzer from './pages/AlzerHotel/RoomType/RoomTypeDetail.jsx';
import PhotoGallery from './pages/PhotoGallery/PhotoGallery';
import Contact from './pages/Contact/Contact';
import ContactAlzer from './pages/AlzerHotel/Contact/Contact.jsx';
import About from './pages/About/About';
import AlzerHotelAbout from './pages/AlzerHotel/About/About.jsx';
import AlzerHotelGallery from './pages/AlzerHotel/PhotoGallery/PhotoGallery.jsx';
import RoomTypeDetail from './pages/RoomType/RoomTypeDetail';
import OnlineRes from './pages/OnlineReservation/OnlineRes';
import OnlineResAlzer from './pages/AlzerHotel/OnlineReservation/OnlineRes.jsx';
import NotFound from './components/NotFound/NotFound';
import AlzerHotelHome from './pages/AlzerHotel/Home/AlzerHome.jsx';
import SelectionPage from './pages/SelectionPage';

function App() {
  const location = useLocation();
  const isAlzerHotel = location.pathname.startsWith('/sultanahmethotel');
  const isKaleiciHotel = location.pathname.startsWith('/kaleicihotel');
  const isSelectionPage = location.pathname === '/';

  return (
    <>
      <div className="main-content">
        {!isSelectionPage && (
          <>
            {isAlzerHotel ? <HeaderAlzer /> : <Header />}
          </>
        )}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<SelectionPage />} />
          <Route path='/kaleicihotel/home' element={<Home />} />
          <Route path='/kaleicihotel/about-us' element={<About />} />
          <Route path='/kaleicihotel/contact-us' element={<Contact />} />
          <Route path='/kaleicihotel/photogallery' element={<PhotoGallery />} />
          <Route path='/kaleicihotel/roomtype' element={<RoomType />} />
          <Route path='/kaleicihotel/roomtype-detail/:title' element={<RoomTypeDetail />} />
          <Route path='/kaleicihotel/OnlineRes' element={<OnlineRes />} />
          <Route path='/sultanahmethotel/home' element={<AlzerHotelHome />} />
          <Route path='/sultanahmethotel/contact-us' element={<ContactAlzer />} />
          <Route path='/sultanahmethotel/RoomTypeAlzer' element={<RoomTypeAlzer />} />
          <Route path='/sultanahmethotel/roomtype-detail-Alzer/:title' element={<RoomTypeDetailAlzer />} />
          <Route path='/sultanahmethotel/OnlineRes' element={<OnlineResAlzer />} />
          <Route path="/sultanahmethotel/about-us" element={<AlzerHotelAbout />} />
          <Route path="/sultanahmethotel/photogallery" element={<AlzerHotelGallery />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
      {!isSelectionPage && (
        <>
          {isAlzerHotel ? <FooterAlzer /> : <Footer />}
        </>
      )}
    </>
  );
}

export default App;
