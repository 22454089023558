import React, { useState } from 'react'
import "./RoomCard.css";
import { Link } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { 
  FaWifi, FaTv, FaSnowflake, FaCoffee, FaExpandArrowsAlt,
  FaUsers, FaMountain, FaBed, FaMapMarkerAlt, FaSubway, FaPlane,
  FaBath, FaGlassMartini, FaBell, FaHome, FaDoorOpen, FaStar,
  FaLock, FaUtensils, FaShieldAlt, FaBroom, FaShower, FaHeadset,
  FaAppleAlt, FaUser, FaStore, FaHandSparkles, FaConciergeBell,
  FaUmbrellaBeach,FaChevronLeft,FaChevronRight
} from "react-icons/fa";
import { BiSolidCity } from 'react-icons/bi';

const amenityIcons = {
  wifi: { icon: FaWifi, name: "Yüksek Hızlı Wi-Fi" },
  tv: { icon: FaTv, name: "TV" },
  "air-conditioner": { icon: FaSnowflake, name: "Klima" },
  aircon: { icon: FaSnowflake, name: "Klima" },
  coffee: { icon: FaCoffee, name: "Kahve Makinesi" },
  bed: { icon: FaBed, name: "Yatak" },
  shower: { icon: FaShower, name: "Duş" },
  bathtub: { icon: FaBath, name: "Küvet" },
  minibar: { icon: FaGlassMartini, name: "Minibar" },
  person: { icon: FaUser, name: "Kişi Sayısı" },
  single: { icon: FaUser, name: "Tekli Yatak" },
  "room-service": { icon: FaConciergeBell, name: "Oda Servisi" },
  kitchen: { icon: FaHome, name: "Mutfak" },
  balcony: { icon: FaUmbrellaBeach, name: "Özel Balkon" },
  "sea-view": { icon: BiSolidCity, name: "Deniz Manzarası" },
  safe: { icon: FaLock, name: "Güvenli Kasa" },
  breakfast: { icon: FaUtensils, name: "Kahvaltı" },
  security: { icon: FaShieldAlt, name: "Akıllı Güvenlik" },
  maid: { icon: FaBroom, name: "Kat Hizmetleri" },
  support: { icon: FaHeadset, name: "24/7 Çevrimiçi Destek" },
  fruit: { icon: FaAppleAlt, name: "Meyve İkramı" },
  team: { icon: FaUsers, name: "Uzman Ekip" },
  market: { icon: FaStore, name: "Yakında market" },
  towel: { icon: FaHandSparkles, name: "Havlu" },
  default: { icon: FaWifi, name: "Özellik" }
};

const RoomCard = ({room, isSelected}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === room.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === 0 ? room.images.length - 1 : prevIndex - 1
    );
  };

  return (
    <Card className={`mb-3 room-card ${isSelected ? 'selected' : ''}`}>
      <div className="room-content">
        <div className="room-image-container">
          <img
            src={room.images[currentImageIndex]}
            alt={`${room.title} - ${currentImageIndex + 1}`}
            className="room-image"
          />
          <div className="image-indicators">
            {room.images.map((_, index) => (
              <span 
                key={index} 
                className={`indicator ${index === currentImageIndex ? 'active' : ''}`}
                onClick={() => setCurrentImageIndex(index)}
              ></span>
            ))}
          </div>
          <button className="image-nav prev" onClick={prevImage}><FaChevronLeft /></button>
          <button className="image-nav next" onClick={nextImage}><FaChevronRight /></button>
        </div>
        <Card.Body>
          <h3 className="room-title">{room.title}</h3>
          <p className="room-description">{room.description}</p>
          <p className="room-info">{room.size} m² | Min {room.minPerson} Konuk | {room.view}</p>
          <div className="amenities">
  {room.amenities && room.amenities.map((amenity, index) => {
    const iconInfo = typeof amenity === 'string'
      ? amenityIcons[amenity]
      : amenityIcons[amenity.icon] || amenityIcons.default;
    const IconComponent = iconInfo.icon;
    return (
      <div key={index} className="amenity-icon-container">
        <span className="amenity-icon" title={iconInfo.name}>
          <IconComponent />
        </span>
        <span className="amenity-name">{amenity.name || iconInfo.name}</span>
      </div>
    );
  })}
</div>
         {/*  <div className="room-price">
            <span className="original-price">{room.price} TL</span>
            <span className="discounted-price">{room.afterDiscount} TL</span>
          </div>
          */}
          <div className="card-actions">
          <a className="primaryBtn" href={`/kaleicihotel/roomtype-detail/${encodeURIComponent(room.title)}`} >
              Daha Fazla
          </a>
            <a variant="primary" className="primaryBtn" href={`/kaleicihotel/OnlineRes`}>REZERVASYON</a>
          </div>
        </Card.Body>
      </div>
    </Card>
  )
}

export default RoomCard